import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ChartFilter, TableDataForChart } from 'src/app/models/chartTool.model';
import { CustomDataModel } from 'src/app/models/CustomDataModel';
import { GenericModel } from 'src/app/models/GenericModel.model';
import { SurveyDataMetadata, SurveyDataRequestBody, SurveyEvent } from 'src/app/models/surveyData.model';
import { ApiService } from 'src/app/services/api.service';
import { DataTableService } from 'src/app/services/data-table.service';
import { ExcelExportService } from "src/app/services/excel-export.service";
import { PdfExportService } from "src/app/services/pdf-export.service";

@Component({
  selector: "app-data-table",
  templateUrl: "./data-table.component.html",
  styleUrls: ["./data-table.component.scss"],
})
export class DataTableComponent implements OnInit {
  TableData: any;
  ColumnHeaders: GenericModel[];
  tableHeaderRow: any[] = [];
  multiHeaderRow: any[][] = [];
  TableReady: boolean = false;
  headersReady: boolean = false;
  //supression count is the amount of dcount necessary to be displayed. if dcount < supression then it is NA instead.
  supressioncount: number = 100;
  TableOrganized: any[][] = [];
  TableAllRecords: any[][] = [];
  tablestring: string;
  dataTitle: string;
  dataSubtitle: string = "";
  requestBody: SurveyDataRequestBody;
  requestMetadata: SurveyDataMetadata;
  rowCategoryColspan = 0;
  Multiyear: boolean = false;
  Multiyeartableorganize: any[][][] = [];
  msadata: any[];
  filter1VariableData: CustomDataModel[];
  columnData: CustomDataModel[];
  rowData: CustomDataModel[];
  footnotes: string[] = [];
  dataHasNA = false;
  dataHasDash = false;
  dataTableType: string = "";
  showExport = true;
  SubtitleData: any[];
  @Output() tableDataEvent = new EventEmitter<TableDataForChart>();

  constructor(
    private apiService: ApiService,
    private dataTableService: DataTableService,
    private excelExportService: ExcelExportService,
    private pdfExportService: PdfExportService
  ) { }

  async ngOnInit(): Promise<void> {
    this.multiHeaderRow[0] = [];
    this.multiHeaderRow[1] = [];
    this.multiHeaderRow[2] = [];
    this.GetDataForSubTitle();
  }

  async getData(requestObj: SurveyEvent): Promise<void> {
    this.requestBody = requestObj.requestBody;
    this.requestMetadata = requestObj.requestMetadata;
    this.msadata = requestObj.msadata;
    this.filter1VariableData = requestObj.filter1VariableData;
    this.columnData = requestObj.columnData;
    this.rowData = requestObj.rowData;
    this.dataHasNA = false;
    this.dataHasDash = false;
    this.TableReady = false;
    // Only fetch data if certain variables changed
    if (this.requestBody.needNewData) {
      const response = await this.apiService.getSurveyData(this.requestBody);
      if (response) {
        if (response.length > 0) {
          this.TableData = response;
          if (this.requestBody.year === "multi") {
            this.Multiyear = true;
            await this.organizemultiyeartable();
          } else {
            this.Multiyear = false;
            await this.organizetable();
          }
        } else {
          // Clear any previous records 
          this.createTableWithNoData();
        }
      }
    } else {
      this.Multiyear = this.requestBody.year === "multi" ? true : false;
      this.filterCurrentData();
    }
    this.createDataTitle();
    this.createDataSubtitle();
    this.prepareFootnotes();
    this.sendTableData();
  }

  createTableWithNoData() {
    this.showExport = false;
    this.TableAllRecords = [];
    this.tableHeaderRow = [];
    this.multiHeaderRow = [];
    const tablestring = '<tr> <td style="border: none; padding:0"> <b> Not enough observations to provide estimates for this table. Please select a broader geography or remove additional filters. </b></td></tr>';
    const tbodySingleYear = document.getElementsByTagName('tbody')[0];
    const tbodyMultiYear = document.getElementsByTagName('tbody')[1];
    if (this.Multiyear) {
      tbodySingleYear.innerHTML = '';
      tbodyMultiYear.innerHTML = tablestring;
      let yearHeaderRow = document.getElementById("yearHeaderRow");
      yearHeaderRow.innerHTML = '';
    } else {
      tbodySingleYear.innerHTML = tablestring;
      tbodyMultiYear.innerHTML = '';
    }

    this.TableReady = true;
  }

  createDataTitle(): void {
    let { yVariableText, xVariableText, area, year } = this.requestMetadata;
    if (area === 'National') {
      area = 'Nation';
    } else if (this.requestBody.chartType === 'map') {
      area = 'All States';
    }
    year = year === 'multi' ? "Multiyear" : year;
    this.dataTitle = `${yVariableText} for ${area}, ${year}`;
    if (xVariableText !== undefined && xVariableText.length > 0) {
      this.dataTitle += ` by ${xVariableText}`;
    }
  }
  CreateSubtitle()
{
  return this.dataTableService.createSubtitle(this.requestBody);
}
  GetDataForSubTitle() {
    this.apiService.getDataForSubTitle().then((x) => (this.SubtitleData = x));
  }
  createDataSubtitle(): void {
    const { filterText, filterText2, filterVarValue, filterVarValue2, colVarValue } = this.requestMetadata;
    this.dataSubtitle = "";

    if (this.requestBody.colVar) {
      let title = this.SubtitleData.find(
        (o) => o.VariableName === this.requestBody.colVar
      );
      if (title != null && title.Notes != null) this.dataSubtitle = title.Notes;
    }
    if (this.dataSubtitle == "") {
      this.dataSubtitle = "All households";
    }
    //let subtitle = 'All Households';
    if (this.requestBody.chartType === 'map') {
      this.dataSubtitle  += `, ${colVarValue[0]}`;
    }
    if (filterText !== undefined && filterText !== '') {
      this.dataSubtitle  += `, ${filterText} is ${filterVarValue.join(', ')}`;
    }
    if (filterText !== undefined && filterText2 !== "") {
      this.dataSubtitle  += `, ${filterText2} is ${filterVarValue2.join(", ")}`;
    }
   // this.dataSubtitle = subtitle;
  }

  async organizetable(): Promise<void> {
    const chartObj: ChartFilter = {
      chartType: this.requestBody.chartType,
      colVarValue: this.requestMetadata.colVarValue,
      rowvarsValues: this.requestMetadata.rowvarsValues,
    };
    let {
      tablestring,
      tableHeaderRow,
      tableAllRecords,
      dataHasNA,
      dataHasDash,
      showTable,
    } = this.dataTableService.organizetable(
      this.TableData,
      this.requestBody,
      chartObj
    );
    // Check false this way because usually showTable will be undefined - a check just for pie chart and map
    if (showTable === false) {
      this.createTableWithNoData();
    } else {
      this.showExport = true;
      this.dataHasNA = dataHasNA;
      this.dataHasDash = dataHasDash;
      this.tableHeaderRow = tableHeaderRow;
      this.TableAllRecords = tableAllRecords;
      let tbody = document.getElementsByTagName("tbody")[0];
      tbody.innerHTML = tablestring;
      this.TableReady = true;
    }
  }

  async organizemultiyeartable(): Promise<void> {
    const chartObj: ChartFilter = {
      chartType: this.requestBody.chartType,
      colVarValue: this.requestMetadata.colVarValue,
      rowvarsValues: this.requestMetadata.rowvarsValues,
    };
    let {
      tablestring,
      yearHeaderString,
      multiHeaderRow,
      tableAllRecords,
      dataHasNA,
      dataHasDash,
    } = this.dataTableService.organizeMultiyearTableForChart(
      this.TableData,
      this.requestBody,
      chartObj
      );
    this.dataHasNA = dataHasNA;
    this.dataHasDash = dataHasDash;
    this.multiHeaderRow = multiHeaderRow;
    let tbody = document.getElementsByTagName("tbody")[1];
    tbody.innerHTML = tablestring;
    let yearHeaderRow = document.getElementById("yearHeaderRow");
    yearHeaderRow.innerHTML = yearHeaderString;
    this.TableAllRecords = tableAllRecords;
    this.TableReady = true;
  }

  filterCurrentData(): void {
    const chartObj: ChartFilter = {
      chartType: this.requestBody.chartType,
      colVarValue: this.requestMetadata.colVarValue,
      rowvarsValues: this.requestMetadata.rowvarsValues,
    };
    if (this.Multiyear) {
      let {
        tablestring,
        yearHeaderString,
        multiHeaderRow,
        tableAllRecords,
        dataHasNA,
        dataHasDash,
      } = this.dataTableService.CreatefixedMultitableForChart(chartObj);

      this.dataHasNA = dataHasNA;
      this.dataHasDash = dataHasDash;
      this.multiHeaderRow = multiHeaderRow;
      let tbody = document.getElementsByTagName("tbody")[1];
      tbody.innerHTML = tablestring;
      let yearHeaderRow = document.getElementById("yearHeaderRow");
      yearHeaderRow.innerHTML = yearHeaderString;
      this.TableAllRecords = tableAllRecords;
      this.TableReady = true;
    } else if (chartObj.chartType === 'map') {
      let {
        tablestring,
        tableHeaderRow,
        tableAllRecords,
        showTable,
      } = this.dataTableService.createFixedTableForChart(this.requestBody, chartObj);// this.dataTableService.constructDataTableSingleRow(chartObj);
      if (showTable === false) {
        this.createTableWithNoData();
      } else {
        this.tableHeaderRow = tableHeaderRow;
        this.TableAllRecords = tableAllRecords;
        let tbody = document.getElementsByTagName("tbody")[0];
        tbody.innerHTML = tablestring;
        this.showExport = true;
        this.TableReady = true;
      }
    } else {
      let {
        tablestring,
        tableHeaderRow,
        tableAllRecords,
        dataHasNA,
        dataHasDash,
      } = this.dataTableService.Createfixedtable(this.requestBody, chartObj); //this.dataTableService.createFixedTableForChart(this.requestBody, chartObj);
      this.dataHasNA = dataHasNA;
      this.dataHasDash = dataHasDash;
      this.tableHeaderRow = tableHeaderRow;
      this.TableAllRecords = tableAllRecords;
      let tbody = document.getElementsByTagName("tbody")[0];
      tbody.innerHTML = tablestring;
      this.TableReady = true;
    }
  }

  prepareFootnotes() {
    // Footnote order:
    // Year
    // Filter 1 (Filter 2 ignored)
    // Y Value
    // X Value
    // NA
    // Dash
    // MSA
    this.footnotes = [];

    //note for data source: "Source: 2017 FDIC National Survey of Unbanked and Underbanked Households."
    let year =
      this.requestBody.year === "multi" ? "Multiyear" : this.requestBody.year;
      if (year === "2021" ) {
        this.footnotes.push(
          "Source: 2021 FDIC National Survey of Unbanked and Underbanked Households."
        );
    } 
    if(year=="2019")
    {
      this.footnotes.push("Source: 2019 FDIC Survey of Household Use of Banking and Financial Services.")
    }
    if( (+year >= 2013 && +year <=2017))
    {
      this.footnotes.push(`Source: ${year} Survey of Unbanked and Underbanked Households`)
      this.footnotes.push(`Estimates may not match previously published estimates because missing values in the ${year} data have been replaced with imputed data.`)

    }
    if(+year<2013)
    {
      this.footnotes.push(`Source: ${year} Survey of Unbanked and Underbanked Households`)

    }
    if(year == "Multiyear")
    {
      this.footnotes.push("Source: Multiyear FDIC National Surveys of Unbanked and Underbanked Households.")

      this.footnotes.push("2017 estimates may not match previously published estimates because missing values in the 2017 data have been replaced with imputed data.")
    }
  

    // If no records were returned, only add year footnote
    if (this.TableAllRecords != null && this.TableAllRecords.length > 0) {
      // Only first filter variable is checked - second one does not appear
      if (
        this.requestBody.filterVar &&
        this.requestBody.filterVarValue.length > 0
      ) {
        const filter = this.filter1VariableData.find(
          (x) => x.VariableName == this.requestBody.filterVar
        );
        if (filter !== undefined && filter.Notes != null) {
          this.footnotes.push(filter.Notes);
        }
      }

      //note for selected colvar - For very first call, columnData won't exist yet.
      if (this.requestBody.colVar && this.columnData !== undefined) {
        const colVarObj = this.columnData.find(
          (col) => col.VariableName === this.requestBody.colVar
        );
        if (colVarObj !== undefined && colVarObj.Notes != null) {
          this.footnotes.push(colVarObj.Notes);
        }
      }

      //note for selected rowvar - For very first call, rowData won't exist yet.
      if (this.requestBody.rowvars && this.rowData !== undefined) {
        const rowVarObj = this.rowData.find(
          (row) => row.VariableName === this.requestBody.rowvars[0]
        );
        if (rowVarObj !== undefined && rowVarObj.Notes != null) {
          this.footnotes.push(rowVarObj.Notes);
        }
      }
      //note if there's NA in data: "NA indicates that the sample size is too small to produce a precise estimate." - multiyear does not show NA footnote
      if (this.dataHasNA) {
        this.footnotes.push(
          "NA indicates that the sample size is too small to produce a precise estimate."
        );
      }
      //note if there's - in data: " - indicates an estimate of zero. The population proportion may be slightly greater than zero." - multiyear does not show dash footnote
      if (this.dataHasDash) {
        this.footnotes.push(
          " - indicates an estimate of zero. The population proportion may be slightly greater than zero."
        );
      }

      //note if MSA with ~ is selected
      if (
        this.requestBody.msa > 0 &&
        this.msadata[
          this.msadata.findIndex((x) => x.Code == this.requestBody.msa)
        ].Value.includes("~")
      ) {
        this.footnotes.push(
          "~ indicates areas with geographic boundary changes between the 2013 and 2015 surveys."
        );
      }
    }
    // Need to filter out any duplicate footnotes (if Y Topic, X Topic, filters are same)
    this.footnotes = this.footnotes.filter(
      (val, idx, self) => self.indexOf(val) === idx
    );
    for (var i = 0; i< this.footnotes.length;i++)
    {
      if(this.footnotes[i].length==0)
      {
       this.footnotes.splice(i,1);
      }
    }
    return this.footnotes;
  }

  sendTableData(): void {
    this.tableDataEvent.emit({
      tableData: this.TableAllRecords,
      dataTitle: this.dataTitle,
      dataSubtitle: this.dataSubtitle,
      tableHeaderRow: this.tableHeaderRow,
    });
  }

  exportexcel(): void {
    // Identify the file name to use
    let excelFileName =
      this.dataTitle
        .trim()
        .replace(/[,|~|.]/g, "")
        .replace(/\s/g, "_") + ".csv";
    // Get the table id to export
    let element =
      "multi" === this.dataTableType
        ? document.getElementById("DataTableMulti")
        : document.getElementById("DataTable");

    let headnote = '"' + this.dataTitle + '"\n\n';
    let footnotes = document.getElementById("footnotes").querySelectorAll("li");

    // Call Excel Service
    this.excelExportService.exportToExcel(
      excelFileName,
      element,
      headnote,
      footnotes
    );
  }

  public exportpdf() {
    let pdfFileName = this.dataTitle
      .replace(/[,|~|.]/g, "")
      .replace(/\s/g, "_");
    let element =
      "multi" === this.dataTableType
        ? document.getElementById("DataTableMulti")
        : document.getElementById("DataTable");
    let footnotes = document.getElementById("footnotes");

    let pdfParams = {
      fileName: pdfFileName,
      dataTable: element,
      title: this.dataTitle,
      footNote: footnotes,
    };
    this.pdfExportService.downloadPDF(pdfParams);   
  }

  export(event, tableType?) {
    this.dataTableType = tableType || "";
    if ("downloadcsv" === event.srcElement.classList[1]) {
      this.exportexcel();
    } else if ("downloadpdf" === event.srcElement.classList[1]) {
      this.exportpdf();
    }
  }
}